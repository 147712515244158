@media only screen and (max-width: 640px) {
    .portfolio-element-wrapper-left {
        
        float: left !important;
    }
    .portfolio-element-wrapper-right {
        margin-left: 30px !important;
        float: left !important;
    }
    .portfolio-element-wrapper {
        width: 80vw !important;
        margin-bottom: 40px;
    }
}
@media only screen and (min-width: 1000px) {
    .portfolio-element-wrapper  {
        width: 60vw;
        
        margin: auto;
    }
    .portfolio-content {
        width: 80vw !important;
        
        margin: auto;
    }
    
}
body{
    background-color: white;
}
.portfolio-wrapper {
    width: 100vw;
    
    display: inline-block; 
}
.intro {
    
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(to bottom, rgb(0,0,0,1) -20%, rgb(0,0,0,0) 100%);
    color: white;
    display: flex;
    
    align-items: center;
}
.intro-text {
    
    margin: auto;
}
.intro-text * {
    
    text-align: center;
    width: 75vw;
}
.bg-elements {

    position: fixed;
    top: 0;
    z-index: -10;
}
.portfolio-element-content {
    background-color: rgb(0,0,0,0);
}
.portfolio-element-wrapper {
    border-radius: 20px;
    border: 2px solid goldenrod;
    background-image: linear-gradient(135deg, rgba(221, 173, 0, 0.8), rgba(255, 157, 0, 0.4) 80%);
    width:36vw;
    max-width: 490px;
    padding: 10px;
    margin-left: 30px;
    
    margin-top: 10px;
    
}
.portfolio-element-boundary {
    width: 90vw;
    display: block;
    margin: auto;
    display: table-row;
}
.portfolio-element-wrapper-left {
    margin-right: 20vw;
    float: left;
}
.portfolio-element-wrapper-right {
    margin-left: 20vw;
    float: right;
}


.portfolio-element-wrapper * {
    margin-bottom: 5px;
}

.portfolio-element-title {
    margin-top: 10px;
    font-family: 'Kanit', sans-serif;
    font-size: 2.5em;
    line-height: 0.95em;
    
}
.portfolio-element-date {
    margin-bottom: -5px;
    color: rgb(97, 97, 97, 1);
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
}
.portfolio-element-desc {
    font-family: 'Rubik', sans-serif;  
}
.portfolio-element-skills span {
    font-family: 'DM Sans', sans-serif; 
    color: rgb(97, 97, 97, 1);
    font-weight: bold;
}
.portfolio-element-skills {
    font-family: 'Rubik', sans-serif;
}
.portfolio-content {
    margin: auto;
    width: 94vw;
    display: inline-block;
    margin-bottom: 10vh;
    display: table;

}
.portfolio-element-links span {
    font-family: 'DM Sans', sans-serif; 
    color: rgb(97, 97, 97, 1);
    font-weight: bold;
}
.portfolio-element-links a {
    margin-right: 7px;
    font-family: 'Rubik', sans-serif;  
    color: #212529;
    
}
#canvasbg {
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100vh;
}