.main-footer {
    background-color: #020122;
    width: 100vw;
    height: 22vh;
    z-index: 1000;
    
}
.footer-container {

    padding-top: 2vh;
    margin-left: 7vw;
    margin-right: 7vw;
}
.footer-logo-box {
    border-right: 1px solid grey;
}
.footer-logo-text {
    font-family: 'Bungee Shade', cursive;
    text-align:center;
    color: white;
    font-size: 3.5vw;
    width: 99%;
}
.footer-nav {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
    max-width: 55vw;
    width: 100%;
}
.footer-nav-link {
    color: white;
    font-family: Alexandria, sans-serif;
    text-decoration: none;
    display: block;
    margin: auto;
    justify-content: center;
    font-size: 2.2vw;
}

.footer-nav-link:visited {
    color: white;
    font-family: Alexandria, sans-serif;
    text-decoration: none;
}
.footer-nav-link:hover {
    font-weight: 1000;
    transition: 0.7s;
    color: white;
    text-decoration: underline;
}
.footer-link-box {

    flex-grow: 1;
    align-self: baseline;
    display: flex;
}
.footer-link-box:first-child {
    
    
    margin-left: 0.5vw;
    margin-right: 0.5vw;
}
.footer-link-dot {
    flex-grow: 1;
    align-self: baseline;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    font-size: 3vw;
    content: "•";
    color: white;
    text-align: center;
    text-decoration: none;
}
.footer-tnc {
    
    margin: auto;
    color:grey;
    text-align:center;
    font-size: 2vw;
}
.footer-tnc-link {
    text-decoration: underline;
    color:grey;
    
}
.footer-tnc-link:visited {
    text-decoration: underline;
    color:grey;
}
.footer-row {
    margin-top: 20px;;
}
.footer-tnc-row {
    margin-top: 15px;
}
@media only screen and (max-width: 640px) {
    .footer-logo-text {
        font-size: 22.4px;
    }
    .footer-nav-link {
        font-size: 14.1px;
    }
    .footer-container {
        margin-left: auto;
        margin-right: auto;
    }
}
@media only screen and (min-width: 640px) {
    
    .footer-container {
        max-width: 86vw;
    }
}