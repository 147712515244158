.scroller {
    position: relative;
    border-radius: 20px;
    
    height: 30px;
    overflow: hidden;
    /*border: 2px solid black;*/
}
.scroller-ul {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.scroller-ul:first-child {
    
  
    
}
.scroller-li {
    transition: 0.5s;
    margin-top: 40px;
    position: absolute;
    left: 200px;
    padding-left: 5px;
    margin-left: 0px;
    display: inline-block;
}