
@media only screen and (max-width: 640px) {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}
.section1 {
    width: 100%;
}
.anim-background {
    position: fixed;
    top: 0;
    z-index: -10;
}
.parallax {
    display: flex;
    min-height: 100vh;
    
    position: relative;
    width: 100vw;
    margin: auto;
    flex-direction: column;

}

.intro-sec1-wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
}
.intro-sec1 {
    display: block;
    justify-content:center;
    align-items: center;
    text-align: center;
    height: 30vh;
    width: 80vw;
    margin: auto;
    color: white;
    background-image: radial-gradient(ellipse,rgb(0,0,0,0.5), rgb(0,0,0,0.5) 50%,rgb(0,0,0,0) 72%)
}
.intro-sec1 h1 {
    font-family: 'Kanit', sans-serif;
    position: relative;
    font-size: 80px;
    animation-name: flyinTop;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    
}
.intro-row {
    position: relative;
    animation-name: flyinBottom;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    margin-top: 20px;
}
.intro-row h3 {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    
}
.intro-row .scroller {
    
    height: 30px;
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    margin: auto;
    font-size: 30px;
}
.intro-sec {
    margin-top: 30px;
    margin-bottom: 30px;
}
.about-me {
    /*background-image: linear-gradient(to bottom, rgb(0,0,0,0), rgb(0,0,0,0.5) 20%, rgb(0,0,0,0.5) 80%, rgb(0,0,0,0) 100%)*/
    background-image: radial-gradient(ellipse,rgb(0,0,0,0.5), rgb(0,0,0,0.5) 50%,rgb(0,0,0,0) 80%)

}
.about-me-header {
    font-family: 'Kanit', sans-serif;
    color: white;
    margin: auto;
}
.about-me-content {
    font-family: 'DM Sans', sans-serif;
    color: white;
    margin: auto;
    font-size: 20px;
    border-radius: 20px;
    padding: 20px;
    border: 2px solid goldenrod;
    background-image: linear-gradient(135deg, rgba(183, 144, 3, 0.9), rgba(255, 157, 0, 0.6) 80%);
}
.intro-sec3 {
    margin-top: 80px;
    margin-bottom: 200px;
}
@media only screen and (max-width: 590px) {
    .intro-sec1 {
        
        width: 90vw;
        
    }
    .intro-sec1 h1 {
        font-family: 'Kanit', sans-serif;
        position: relative;
        font-size: 10vw;
        animation-name: flyinTop;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
    
    }
}
@keyframes flyinTop {
    0%   {
        
        bottom: 200px;
        text-align: center;
    }

    
    
    100% {
        bottom: 0px;
        
    }
    
  }
  @keyframes flyinBottom {
    0%   {
        
        top: 200px;
        text-align: center;
    }

    
    
    100% {
        top: 0px;
        
    }
    
  }