.animatedBox {
    position: relative;
    height: 100vh;
    
}
.animatedBoxContent {
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: -1;
    overflow: hidden;
}
.animatedBoxCanvas {
    position: absolute;
    bottom: 0;
}

.animatedBoxBackgroundImage {
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
    width: 100vw;
}
.animatedBoxText {
    position: absolute;
    
    right: 20px;
    bottom: 8vh;
    width: 40vw;
    z-index: 100;
    float: right;
    color: white;
    text-shadow: 1px gray;
    
    
}
.animatedBoxHeaders {
    margin: auto;
    padding-left: 5vw;
    

}


@media only screen and (max-width: 500px) {
    .animatedBoxHeaders a h2 {
        text-align: right;
       
     }
     .animatedBoxHeaders h3 {
        text-align: right;
       
     }
     .animatedBoxHeaders .ctaSection {
        float: right;
       
     }
    .animatedBoxBackgroundImage {
        
        /*object-position: -50vw;*/
        
    }
    .animatedBoxText {
        position: absolute;
        
        float:right;
        
        bottom: 20px;
        width: 70vw;
        z-index: 100;
        
        color: white;
        text-shadow: 1px gray;
        
    }
    
}
.animatedBoxHeaders a h2 {
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    color: white;
    text-decoration: underline goldenrod solid;
    text-decoration-thickness: 2px;
    font-size: 2em;
    margin-bottom: 5px;
}
.animatedBoxHeaders a {
    
    text-decoration:none;
   
}
.animatedBoxHeaders h3 {
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    
    font-size: 1.5em;
}
.cta {
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 18px;
    text-decoration: none;
}
.cta p {
    margin-left: 5px;
}
.ctaSection {
    display: flex;
    flex-direction: row;
}
.phoneCTA {
    display: inline-flex;
}
.emailCTA {
    margin-left: 20px;
    display: inline-flex;
}
@media screen and (max-width: 100vh) {
    .animatedBox {
        height: 70vh;
    }
    .animatedBoxContent {
        height: 70vh;
        
    }
    .animatedBoxBackgroundImage {
        height: 70vh;
        
    }
    .animatedBoxCanvas {
        width: 100vw;
        height: 70vh;
    }
}
@media screen and (min-width: 1060px) {
    .animatedBoxHeaders a h2 {
        font-size: 3em;
       
     }
     .animatedBoxHeaders h3 {
        font-size: 2.5em;
       
     }
     .animatedBoxHeaders .cta {
        font-size: 2em;
       
     }
}
@media only screen and (max-width: 600px) {
    .animatedBoxBackgroundImage {
        
        object-position: -20vw;
    }
}
@media only screen and (max-width: 500px) {
    .animatedBoxHeaders a h2 {
        text-align: right;
       
     }
     .animatedBoxHeaders h3 {
        text-align: right;
       
     }
     .animatedBoxHeaders .ctaSection {
        float: right;
       
     }
    .animatedBoxBackgroundImage {
        
        /*object-position: -50vw;*/
        
    }
    .animatedBoxText {
        position: absolute;
        
        float:right;
        
        bottom: 20px;
        width: 70vw;
        z-index: 100;
        
        color: white;
        text-shadow: 1px gray;
        
    }
    
}

