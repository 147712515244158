
                
                
                .content {
                    height: 92.5vh;;
                }
                #threejscanvas1 {
                    z-index: -1;
                }
                #threejsbackdrop {
                    position: absolute;
                    z-index: -2;
                    background-color: #000000;
                
                }
                .login-container {
                    height: 92.5vh;
                    display: flex;
                    align-items: center;
                }
                .login-area {
                    background-color: rgba(255,255,255,.2);
                    backdrop-filter: blur(5px);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex-wrap: nowrap;
                    /*min-width: 300px;
                    width: 80vw;*/
                    max-width: 700px;
                    margin: auto;
                    z-index: 2;
                    padding: 20px;
                }
                .login-key {
                    display: none;
                }
                .login-label {
                    font-family: 'Alexandria', sans-serif;
                    color: white;
                    margin-right: 5px;
                }
                .login-component {
                    margin: auto;
                    display: flex;
                    flex-direction: row;
                }
                #swap-button {
                    text-decoration: underline;
                    color: black;
                    background-color: white;
                    border: none;
                }
                .button-area {
                    display: flex;
                    flex-direction: column;
                    margin: auto;
                }
                .login-buttons {
                    margin: auto;
                    margin-top: 10px;
                    
                }
                .login-field {
                    flex-grow: 0;
                }
                .login-field input {
                    width: 66%;
                    font-family: 'Alexandria', sans-serif;
                }
                @media only screen and (max-width: 640px){
                    .login-area {
                        background-color: rgba(255,255,255,.2);
                        backdrop-filter: blur(5px);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex-wrap: nowrap;
                        /*min-width: 300px;
                        width: 80vw;
                        max-width: 600px;*/
                        height: 50vh;
                        margin: auto;
                        z-index: 2;
                        padding: 40px;
                        
                    }
                    .login-field input {
                        height: 40px;
                        width: 100%;
                        font-size:24px;
                    }
                    .login-component {
                        margin: auto;
                        display: block;
                        min-width: 80%;
                    }
                    .login-label {
                        font-family: 'Alexandria', sans-serif;
                        color: white;
                        margin-right: 5px;
                        font-size: 2.2em;
                    }
                    .login-field {
                        width: 100%;
                    }
                    .button-area {
                        font-size: 1.7em;
                    }
                    .login-component {
                        margin-bottom: 20px;
                    }
                    .login-buttons {
                        margin-top: 20px;
                    }
                    .button-container{
                        padding-right:calc(var(--bs-gutter-x) * .5);
                        padding-left: calc(var(--bs-gutter-x) * .5);
                    }
                    #submit-button {
                        margin: auto;
                    }
                }
            